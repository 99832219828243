/* @tailwind base; */
@tailwind components;
@tailwind utilities;

:root {
    --adm-color-primary: #00DC4D;
    --adm-color-text: #200E32;
    --adm-color-light: #A0A0A0;
}

@font-face {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("assets/fonts/sf-pro/SFPRODISPLAYREGULAR.OTF") format("otf");
}

@font-face {
    font-family: 'SF-Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("assets/fonts/sf-pro/SFPRODISPLAYMEDIUM.OTF") format("otf");
}

@font-face {
    font-family: 'Campton';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("assets/fonts/campton/CamptonSemiBold.otf") format("otf");
}

@font-face {
    font-family: 'Campton';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("assets/fonts/campton/CamptonSemiBoldItalic.otf") format("otf");
}

html {
    font-family: "SF-Pro", sans-serif;
}

body {
    margin: 0;
    font-family: "SF-Pro", sans-serif;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
    margin: 0;
    /* color: #200E32; */
    font-family: "SF-Pro", sans-serif;
}

body {
    font-family: "SF-Pro", sans-serif;
}

.app {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100vh;
    max-width: 480px;
    position: relative;
    overflow-y: scroll;
    background-color: #fff;
}

.ad-list .adm-list-body,
.ad-list .adm-list-item,
.ad-list .adm-list-item-content,
.ad-list .adm-list-item-content-main {
    border: 0 !important;
    padding: 0;
}

.web3modal-modal-lightbox {
    z-index: 999 !important;
}

.campton {
    font-family: "Campton", sans-serif;
}

.adm-button:not(.adm-button-fill-outline) {
    border: 0;
}

.adm-button span {
    font-size: 16px;
    font-weight: 600;
}

.adm-button.adm-button-large {
    padding: 13px 12px;
}

.adm-nav-bar-back {
    gap: 10px;
    padding: 0;
}

.adm-nav-bar-back span:last-child {
    font-size: 18px;
    font-weight: 500;
}

.adm-nav-bar-back .adm-nav-bar-back-arrow {
    font-size: 18px;
}

.report-radio-group .adm-radio-content {
    flex: 1;
    padding: 0;
}

.referral-input .adm-input-element {
    padding: 16px;
    border-radius: 8px;
    background-color: #DDDFE833;
}

#ad-player,
#ad-player #ad-player_html5_api {
    /* padding: 0 !important;
    height: 220px !important; */
}

@media screen and (min-width: 480px) {
    .popup .adm-popup-body {
        left: 50%;
        width: 480px;
        margin-left: -210px;
        border-radius: 6px 6px 0 0;
    }
}

@media screen and (max-width: 480px) {
    .app {
        margin: 0 auto;
    }

    .desktop-img {
        display: none;
    }
}